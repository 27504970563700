import React, { useEffect } from 'react';
import { Modal, Typography, Form, Input, Divider, notification } from 'antd';
import {
	CloseCircleOutlined,
	ExclamationCircleFilled,
	CheckCircleOutlined,
	UserOutlined,
	FileDoneOutlined,
	CrownOutlined
} from '@ant-design/icons';
import { ReactNode } from 'react';
import authClient from '../../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../../environment';

const { Text } = Typography;
const { TextArea } = Input;

interface RequestModalProps {
	mortgageId: string;
	operationType: string;
	getRecoList: any;
	showModalRequest: boolean;
	setShowModalRequest: React.Dispatch<React.SetStateAction<boolean>>;
	showModalErrors: boolean;
	setShowModalErrors: React.Dispatch<React.SetStateAction<boolean>>;
	errorsList: any;
	optionReco: string;
	saveData: any;
}
interface ColumnData {
	dataKey?: string;
	title?: string;
	icon?: ReactNode;
	showOwner: boolean;
	errors: object;
}

const RequestModal: React.FC<RequestModalProps> = ({
	mortgageId,
	operationType,
	getRecoList,
	showModalRequest,
	setShowModalRequest,
	showModalErrors,
	setShowModalErrors,
	errorsList,
	optionReco,
	saveData
}) => {
	const [form] = Form.useForm();
	const missingData: [] = errorsList?.missingData || [];
	const missingDocuments: any = errorsList?.missingDocuments || {};

	const notificationSuccess = () => {
		notification.success({
			message: 'Solicitud de recomendador enviada',
			description: 'Tu solicitud ha sido enviada con éxito.',
			duration: 4,
			placement: 'top',
			className: 'notification-sucess-reco',
			icon: <CheckCircleOutlined />,
			closeIcon: false
		});
	};

	const notificationError = () => {
		notification.error({
			message: 'Error al enviar la solicitud',
			description: 'Hubo un problema al enviar tu solicitud.',
			duration: 4,
			placement: 'top',
			className: 'notification-error-reco',
			icon: <CloseCircleOutlined />,
			closeIcon: false
		});
	};

	const ownersErrors = {
		name: 'Nombre',
		firstSurname: 'Apellido 1',
		lastSurname: 'Apellido 2',
		phone: 'Teléfono',
		email: 'Correo',
		nif: 'DNI / NIE / Pasaporte',
		numberFamilyMembers: 'Miembros ud. familiar',
		maritalStatus: 'Estado civil',
		birthdate: 'Fecha de nacimiento',
		nationality: 'Nacionalidad',
		cityzenship: 'País de residencia',
		address: 'Dirección actual',
		province: 'Provincia',
		population: 'Población',
		postalCode: 'CP',
		currentlyLiving: 'Dónde vive actualmente',
		monthlyQuota: 'Total gastos mes',
		currentProfession: 'Profesión',
		educationLevel: 'Nivel de estudios',
		activity: 'Situación laboral',
		dateSeniorityCompany: 'Antigüedad empresa actual',
		companyName: 'Empresa',
		companyCIF: 'CIF',
		yearsLivingPostalcode: 'Tiempo residiendo en este CP'
	};

	const desiredOfferErrors = {
		term: 'Plazo deseado',
		interest: 'Tipo de hipoteca que interesa',
		tin: 'TIN'
	};

	const propertyWishPurchaseErrors = {
		address: 'Dirección de compra',
		population: 'Población',
		province: 'Provincia',
		postalCode: 'CP',
		maxBudget: 'Precio de compraventa',
		mortgageAmount: 'Estimación del importe de la hipoteca',
		amount: 'Capital pendiente',
		propertyType: 'Tipo de propiedad',
		houseType: 'Uso previsto de la propiedad',
		subrogationSubtype: 'Subtipo de subrogación',
		dateEndMortgage: 'Fecha de fin'
	};

	const docsErrors = {
		nif: 'DNI',
		laboralLife: 'Vida laboral',
		payroll_2: 'Dos últimas nóminas',
		irpf: 'Último IRPF',
		irpf2: 'Penúltimo IRPF',
		bankreader: 'Movimientos bancarios'
	};

	useEffect(() => {
		if (!showModalRequest) {
			form.resetFields();
		}
	}, [showModalRequest, form]);

	const handleOk = async () => {
		const values = await form.validateFields();
		axios
			.post(
				`${env.api.url}/sb/mortgages/recos/create`,
				{ operationId: mortgageId, comments: values.comments },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.success) {
					getRecoList();
					notificationSuccess();
					saveData('mortgage', 'optionReco', 'recommender');
				} else {
					notificationError();
				}
			})
			.catch(() => {
				notificationError();
			});

		setShowModalRequest(false);
	};

	const showErrorMessages = (type: string, message: string, index: number) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center', gap: 8, marginTop: 4 }} key={index}>
				{type === 'error' && <CloseCircleOutlined style={{ color: '#FF4D4F', fontSize: 18 }} />}
				{type === 'warning' && (
					<ExclamationCircleFilled style={{ color: '#F5CD57', fontSize: 18 }} />
				)}
				{type === 'document' && <img src={'/images/folder_error.svg'} alt="folder_error" />}
				<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>{message}</Text>
				{message === 'Total gastos mes' && (
					<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
						(hipotecas / préstamos / pensiones)
					</Text>
				)}
			</div>
		);
	};

	const groupOwners = (data: any) => {
		const groupedOwners = {};
		data.forEach((item: any) => {
			const [ownerKey, ...rest] = item.split('.');
			if (!groupedOwners[ownerKey]) {
				groupedOwners[ownerKey] = [];
			}
			groupedOwners[ownerKey].push(rest.join('.'));
		});

		return groupedOwners;
	};

	const renderColumnData = ({ dataKey, title, icon, showOwner, errors }: ColumnData) => {
		let filteredData = [];

		if (dataKey === 'employment') {
			filteredData = missingData.filter((item: any) => {
				const keys = item.split('.');
				return keys[0].startsWith('owner') && keys[1] === 'employment';
			});
		} else if (dataKey === 'owner') {
			filteredData = missingData.filter((item: any) => {
				const keys = item.split('.');
				return keys[0].startsWith('owner') && keys.length > 1 && keys[1] !== 'employment';
			});
		} else if (dataKey === 'currentPropertyMortgage') {
			filteredData = missingData.filter(
				(item: any) => item.startsWith(dataKey) || item.startsWith('subrogation')
			);
		} else {
			filteredData = missingData.filter((item: any) => item.startsWith(dataKey));
		}

		if (filteredData.length === 0) return null;

		const groupedOwners = showOwner && groupOwners(filteredData);
		const hasMultipeOwners = Object.keys(groupedOwners).length > 1;

		return (
			<div style={{ padding: '16px 16px 0px 16px' }}>
				<Text style={{ fontSize: 14, fontWeight: 500, color: '#2F4858' }}>
					{icon} {title}:
				</Text>
				<Divider style={{ margin: '0px 0px 8px 0px' }} />
				<div style={{ display: 'flex', gap: 40 }}>
					{showOwner && hasMultipeOwners ? (
						<>
							{Object.keys(groupedOwners).map((key) => {
								return (
									<div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
										{groupedOwners[key].map((item: any, index: number) => {
											const field = item.split('.').pop();
											return showErrorMessages(
												'error',
												`T${key.charAt(5)} - ${errors[field]}`,
												index
											);
										})}
									</div>
								);
							})}
						</>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							{filteredData.map((item: any, index: number) => {
								const field = item.split('.').pop();
								return showErrorMessages(
									'error',
									showOwner ? `T${item.charAt(5)} - ${errors[field]}` : `${errors[field]}`,
									index
								);
							})}
						</div>
					)}
				</div>
			</div>
		);
	};

	return (
		<>
			<Modal
				title="Vas a pedir un RECO Fase 1"
				open={showModalRequest}
				onOk={handleOk}
				okText={'Pedir RECO Fase 1'}
				onCancel={() => setShowModalRequest(false)}
				className="modals-reco">
				<Text style={{ fontSize: 14, fontWeight: 400, color: '#748EA0' }}>
					Le llegará la petición al equipo vertical de RECOs y esta operación aparecerá en el
					listado de RECOS “Pendientes”
				</Text>
				<Form form={form} name="request_form" layout="vertical" style={{ marginTop: 16 }}>
					<Form.Item
						label={
							<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
								Comentarios de la petición
							</Text>
						}
						name="comments"
						rules={[{ required: true, message: 'Por favor, ingrese los comentarios' }]}>
						<TextArea
							style={{ height: 80, borderRadius: 4 }}
							placeholder="Explicación de la operación"
						/>
					</Form.Item>
				</Form>
			</Modal>
			<Modal
				title={
					(optionReco === 'recommender'
						? 'No puedes solicitar la elaboración del recomendador fase I. '
						: '') + 'Revisa los siguientes datos:'
				}
				open={showModalErrors}
				width={680}
				onOk={() => setShowModalErrors(false)}
				okText={'Aceptar'}
				cancelText={'Cancelar'}
				onCancel={() => setShowModalErrors(false)}
				className="modals-reco modals-reco-error">
				<div style={{ marginTop: -10, maxHeight: 400, overflowY: 'auto' }}>
					{(missingData?.length > 0 || Object.keys(missingDocuments).length > 0) && (
						<>
							{missingData?.length > 0 && (
								<>
									<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
										Faltan los siguientes datos de la operación:
									</Text>
									{renderColumnData({
										dataKey: 'owner',
										title: 'Datos personales de los titulares',
										icon: <UserOutlined />,
										showOwner: true,
										errors: ownersErrors
									})}
									{renderColumnData({
										dataKey: 'employment',
										title: 'Datos laborales de los titulares',
										icon: <UserOutlined />,
										showOwner: true,
										errors: ownersErrors
									})}
									{renderColumnData({
										dataKey:
											operationType === 'mortgage'
												? 'propertyWishPurchase'
												: 'currentPropertyMortgage',
										title:
											operationType === 'mortgage'
												? 'Datos de la propiedad que desea comprar'
												: 'Hipoteca actual',
										icon: <FileDoneOutlined />,
										showOwner: false,
										errors: propertyWishPurchaseErrors
									})}
									{renderColumnData({
										dataKey: 'desiredOffer',
										title: 'Datos de la oferta que le interesa al cliente',
										icon: <CrownOutlined />,
										showOwner: false,
										errors: desiredOfferErrors
									})}
								</>
							)}
							{Object.keys(missingDocuments).length > 0 && (
								<div style={{ marginTop: missingData.length > 0 && 16 }}>
									<Text style={{ fontSize: 14, fontWeight: 600, color: '#2F4858' }}>
										Faltan los siguientes documentos:
									</Text>
									<Divider style={{ margin: 0 }} />
									<div style={{ display: 'flex', gap: 40 }}>
										{Object.keys(missingDocuments).map((key: any) => {
											return (
												<div style={{ flex: '1 1 50%', display: 'flex', flexDirection: 'column' }}>
													{missingDocuments[key].map((item: any, index: number) => {
														const field = item.split('.').pop();
														return showErrorMessages(
															'document',
															`T${key.charAt(5)} - ${docsErrors[field]}`,
															index
														);
													})}
												</div>
											);
										})}
									</div>
								</div>
							)}
						</>
					)}
				</div>
				<Divider style={{ margin: '16px 0px' }} />
				{showErrorMessages('warning', 'Asegúrate de que no tiene préstamos activos.', 0)}
				{showErrorMessages('warning', 'Asegúrate de que no tiene viviendas libres de cargas.', 1)}
			</Modal>
		</>
	);
};

export default RequestModal;
