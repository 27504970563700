import React, { useState, useEffect } from 'react';
import { ReactNode } from 'react';
import {
	Input,
	Checkbox,
	Typography,
	DatePicker,
	Select,
	Steps,
	notification,
	Popover,
	message,
	Radio
} from 'antd';
import Loading from '../../../../../Components/ModalsAndAlerts/Loading';
import authClient from '../../../../../Auth/Auth';
import axios from 'axios';
import env from '../../../../../environment';
import {
	LikeOutlined,
	PhoneOutlined,
	CheckCircleOutlined,
	ClockCircleOutlined,
	CheckOutlined,
	CloseCircleOutlined,
	RiseOutlined,
	StopOutlined,
	InfoCircleOutlined,
	CloseCircleFilled
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import RequestModal from './modals/requestModal';
import RegisterAssigment from '../bank/registerAssignment/registerAssigment';
import _ from 'lodash';
import ModalStatus from './modals/modalStatus';

const { Text } = Typography;
const { Step } = Steps;
const { Option } = Select;

interface StepsRecommendersProps {
	mortgageId: string;
	mortgageData: any;
	operationType: string;
	favOffer: any;
	getMortgageData: (...args: any[]) => any;
	dataBankRequest: any;
	saveData: (...args: any[]) => any;
	mortgageDirectors: { value: string; label: string }[];
}
interface StepTitleProps {
	step: number;
	title: string;
	iconButton?: ReactNode;
	handleClick?: () => void;
}
interface StepDescriptionProps {
	step: number;
	datepickerLabel?: string;
	selectLabel?: string;
	defaultValue1?: string | undefined;
	defaultValue2?: string | undefined;
	defaultValue3?: string | undefined;
	onChange1?: (date: dayjs.Dayjs | null) => void;
	onChange2?: (date: dayjs.Dayjs | null) => void;
	onChange3?: (value: string | null) => void;
	options?: any[];
}
export type ReduxStore = {
	mortgages: StoreAuth;
};

export type StoreAuth = {
	analyst: any;
};

const StepsRecommenders: React.FC<StepsRecommendersProps> = ({
	mortgageId,
	mortgageData,
	operationType,
	favOffer,
	getMortgageData,
	dataBankRequest,
	saveData,
	mortgageDirectors
}) => {
	const profile = authClient.getProfile();
	const statusJob = useSelector((state: any) => state.formaTasaci.statusJob);
	const [visibleModalRequest, setVisibleModalRequest] = useState(false);
	const [visibleModalError, setVisibleModalError] = useState(false);
	const [visibleConfirmComponent, setVisibleConfirmComponent] = useState(false);
	const [symphonyNumber, setSymphonyNumber] = useState(mortgageData?.symphonyNumber);
	const [symphonyNumber2, setSymphonyNumber2] = useState(mortgageData?.symphonyNumber2);
	const [optionReco, setOptionReco] = useState(mortgageData?.optionReco || undefined);
	const [statusReco, setStatusReco] = useState(undefined);
	const [recosList, setRecosList] = useState(undefined);
	const [errorsList, setErrorsList] = useState(undefined);
	const [analist, setAnalist] = useState(undefined);
	const [request, setRequest] = useState(undefined);
	const [modalComments, setModalComments] = useState(false);
	const [modalChecks, setModalChecks] = useState(false);
	const [modalFinish, setModalFinish] = useState(false);
	const [modalCancel, setModalCancel] = useState(false);
	const [dataOperation, setDataOperation] = useState([]);

	const requestStates = {
		pending: {
			name: 'Pendiente',
			color: '#2F4858',
			backgroundColor: '#C0DAEE',
			icon: <ClockCircleOutlined style={{ fontSize: 16 }} />
		},
		active: {
			name: 'En curso',
			color: '#2F4858',
			backgroundColor: '#B0E8DB',
			icon: <RiseOutlined style={{ fontSize: 16 }} />
		},
		blocked: {
			name: 'Bloqueado',
			color: '#2F4858',
			backgroundColor: '#FFDEDE',
			icon: <StopOutlined style={{ fontSize: 16 }} />
		},
		done: {
			name: 'Finalizado',
			color: '#0088C6',
			backgroundColor: '#F1F7F8',
			icon: <CheckOutlined style={{ fontSize: 16 }} />
		},
		canceled: {
			name: 'Cancelado',
			color: '#C0DAEE',
			backgroundColor: '#F1F7F8',
			icon: <CloseCircleOutlined style={{ fontSize: 16 }} />
		}
	};

	useEffect(() => {
		getRecoList();
		getSalesAgents();
	}, []);

	const getSalesAgents = () => {
		axios
			.get(`${env.api.url}/v1/hadmin/sales-agents`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				const tmp = _.find(response.data.data, function (a) {
					return a.id === authClient.getProfile().id;
				});
				setAnalist(tmp);
			});
	};

	const getRecoList = () => {
		axios
			.get(`${env.api.url}/sb/mortgages/recos/list-by-operation/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setRecosList(response.data.data ?? []);
				setStatusReco(response.data.data[0]?.status ?? '');
			})
			.catch((error) => {
				console.log('Error al obtener la lista de recomendadores: ', error);
			});
	};

	const handleSubmitForm = (values: any) => {
		const objAsigment = {
			bankRequestId: dataBankRequest[0].id,
			bankAccountData: {
				name: values.name === undefined ? '' : values.name,
				email: values.email,
				phone: values.phone === undefined ? '' : values.phone,
				office: values.office === undefined ? '' : values.office,
				reference: values.reference === undefined ? '' : values.reference
			}
		};
		axios
			.post(`${env.api.url}/banks/bank-request/assign-to-account-executive`, objAsigment, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then(() => {
				setVisibleConfirmComponent(false);
				notification.success({
					message: 'Registro exitoso',
					description: 'El ejecutivo de Cuentas se ha registrado correctamente.',
					duration: 4,
					placement: 'top',
					className: 'notification-sucess-reco',
					icon: <CheckCircleOutlined />,
					closeIcon: false
				});
				sendEmail(false);
			});
	};

	const saveCallMade = (value1: boolean, value2: string) => {
		axios
			.post(
				`${env.api.url}/v1/mortgages/data/multiple/update/${mortgageId}`,
				{
					values: [
						{
							key: 'mortgage',
							subkey: 'insuranceCall',
							value: value1
						},
						{
							key: 'mortgage',
							subkey: 'insuranceCallDate',
							value: value2
						}
					]
				},
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then(() => {
				getMortgageData();
			});
	};

	const renderOptions = (value: string) => {
		const options = [
			<Option key="pending" value="pending" disabled={value === 'pending'}>
				<ClockCircleOutlined />
				<Text>{' Pendiente'}</Text>
			</Option>,
			<Option key="active" value="active" disabled={value === 'active'}>
				<RiseOutlined />
				<Text>{' En curso'}</Text>
			</Option>,
			<Option key="canceled" value="canceled" disabled={value === 'canceled'}>
				<CloseCircleFilled />
				<Text>{' Cancelado'}</Text>
			</Option>,
			<Option key="done" value="done" disabled={value === 'done'}>
				<CheckOutlined disabled={value === 'done'} />
				<Text>{' Finalizado'}</Text>
			</Option>,
			<Option key="blocked" value="blocked" disabled={value === 'blocked'}>
				<StopOutlined disabled={value === 'blocked'} />
				<Text>{' Bloqueado'}</Text>
			</Option>
		];

		if (analist.type === 'prerisk' && analist.area === 'recommenders' && value === 'pending') {
			return options.filter((option) => option.key === 'active' || option.key === 'pending');
		}

		if (analist.type === 'prerisk' && analist.area === 'recommenders' && value === 'active') {
			return options.filter(
				(option) => option.key === 'active' || option.key === 'blocked' || option.key === 'done'
			);
		}

		if (analist.type === 'prerisk' && analist.area === 'recommenders' && value === 'blocked') {
			return options.filter((option) => option.key === 'blocked');
		}

		if (analist.type === 'prerisk' && analist.area === 'documents' && value === 'blocked') {
			return options.filter((option) => option.key === 'blocked' || option.key === 'active');
		}

		if (analist.type === 'risk' && value === 'pending') {
			return options.filter((option) => option.key === 'pending' || option.key === 'canceled');
		}

		if (analist.type === 'risk' && value === 'blocked') {
			return options.filter(
				(option) => option.key === 'blocked' || option.key === 'active' || option.key === 'canceled'
			);
		}
		return options;
	};

	const sendEmail = (showMessage: boolean) => {
		axios
			.post(
				`${env.api.url}/v1/banks/send-mail-call-insurance`,
				{ bankOfferId: favOffer.id },
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.success) {
					getMortgageData();
					if (showMessage) {
						notification.success({
							message: 'Llamada solicitada',
							description: 'Se ha solicitado la llamada de seguros para tu cliente.',
							duration: 4,
							placement: 'top',
							className: 'notification-sucess-reco',
							icon: <CheckCircleOutlined />,
							closeIcon: false
						});
					}
				} else {
					notification.error({
						message: 'Error al solicitar la llamada',
						description: 'Hubo un problema al solicitar la llamada de seguros para tu cliente.',
						duration: 4,
						placement: 'top',
						className: 'notification-error-reco',
						icon: <CloseCircleOutlined />,
						closeIcon: false
					});
				}
			});
	};

	const checkLinkages = (linkages: any) => {
		if (!linkages) return false;
		const isChecked = (key: string) =>
			linkages.hasOwnProperty(key) && linkages[key].isChecked === true;
		return isChecked('home') || isChecked('insecurance') || isChecked('protectedPayments');
	};

	const validateRequest = (selectedValue: string) => {
		axios
			.post(
				`${env.api.url}/sb/mortgages/recos/validate/${mortgageId}`,
				{},
				{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
			)
			.then((response) => {
				if (response.data.success) {
					if (response.data.data.passed) {
						if (selectedValue === 'recommender') {
							setVisibleModalRequest(true);
						} else {
							axios
								.post(
									`${env.api.url}/sb/mortgages/recos/create-by-salesAgent`,
									{
										operationId: mortgageId,
										comments: 'Scoring reactivo - gestionado por el analista'
									},
									{ headers: { Authorization: `Bearer ${authClient.getToken()}` } }
								)
								.then((response) => {
									if (response.data.success) {
										getRecoList();
										notification.success({
											message: 'Solicitud de recomendador enviada',
											description: 'Tu solicitud ha sido enviada con éxito.',
											duration: 4,
											placement: 'top',
											className: 'notification-sucess-reco',
											icon: <CheckCircleOutlined />,
											closeIcon: false
										});
										saveData('mortgage', 'optionReco', 'reactive');
									} else {
										notification.error({
											message: 'Error al enviar la solicitud',
											description: 'Hubo un problema al enviar tu solicitud.',
											duration: 4,
											placement: 'top',
											className: 'notification-error-reco',
											icon: <CloseCircleOutlined />,
											closeIcon: false
										});
									}
								})
								.catch(() => {
									notification.error({
										message: 'Error al enviar la solicitud',
										description: 'Hubo un problema al enviar tu solicitud.',
										duration: 4,
										placement: 'top',
										className: 'notification-error-reco',
										icon: <CloseCircleOutlined />,
										closeIcon: false
									});
								});
							saveData('mortgage', 'optionReco', 'reactive');
						}
					} else {
						setErrorsList(response.data.data);
						setVisibleModalError(true);
						setOptionReco(undefined);
					}
				} else {
					message.error('Ha habido un error');
				}
			})
			.catch(() => {
				message.error('Ha habido un error');
			});
	};

	const checkOfferType = (result: string) => {
		if (result === 'toGrant') {
			if (favOffer?.type === 'preOffer') {
				notification.info({
					message: '¡Cambia a oferta!',
					description:
						'Has indicado que la respuesta de gestoría es A conceder. ¡No olvides marcar la preoferta como oferta!',
					className: 'notification-info-reco',
					icon: <InfoCircleOutlined />,
					duration: 4,
					placement: 'top',
					closeIcon: false
				});
			}
		}
		saveData('mortgage', 'resultManagement', result);
	};

	const disabledButtonCall = () => {
		if (!favOffer) return true;
		const linkagesValid = checkLinkages(favOffer.data?.entailments[0]?.linkages);
		const emailInInsuranceDefined = mortgageData?.sendEmailInsecurance !== undefined;
		return !linkagesValid || emailInInsuranceDefined;
	};

	const disabledDatepicker = (step: number) => {
		const steps = step === 2 || (step === 3 && _.isNil(mortgageData?.elaboratedScoring));
		const isAdminExceptSilvia =
			step === 3 && profile.type === 'admin' && !profile.email?.includes('silvia.varela');
		const isFernandoOrJaime =
			step === 3 &&
			(profile.email?.includes('fernando.lopez') || profile.email?.includes('jaime.aguirre'));
		if (steps || isAdminExceptSilvia || isFernandoOrJaime) {
			return false;
		}

		return true;
	};

	const textButtonCall = () => {
		if (!favOffer) return 'No hay oferta favorita';
		const hasLinkages = checkLinkages(favOffer.data?.entailments[0]?.linkages);
		const emailInsecuranceDefined = mortgageData.sendEmailInsecurance !== undefined;
		if (hasLinkages) {
			return emailInsecuranceDefined ? 'Llamada solicitada' : 'Solicitar';
		}
		return 'Oferta sin vinculaciones';
	};

	const comprobationData = (mortgageId: any) => {
		axios
			.get(`${env.api.url}/sb/mortgages/recos/validate-finish/${mortgageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setDataOperation(response.data.data);
				setModalFinish(true); //si faltan datos o no comprobar
			});
	};

	const selectModal = (value: any, request: any) => {
		setRequest(request);

		if (analist.type === 'risk' && value === 'canceled') {
			setModalCancel(true);
		}

		if (analist.type === 'prerisk' && analist.area === 'recommenders' && value === 'active') {
			setModalComments(true);
		}

		if (
			analist.type === 'prerisk' &&
			analist.area === 'recommenders' &&
			(value === 'done' || value === 'blocked')
		) {
			if (value === 'blocked') {
				setModalChecks(true);
			}
			if (value === 'done') {
				comprobationData(request.operationId);
			}
		}

		if (analist.type === 'prerisk' && analist.area === 'documents' && value === 'blocked') {
			setModalComments(true);
		}

		if (analist.type === 'risk' && value === 'canceled') {
			if (value === 'canceled') {
				setModalCancel(true);
			} else {
				setModalComments(true);
			}
		}
	};

	const sendinComment = (values: any, status: any, id: any) => {
		axios
			.post(
				`${env.api.url}/sb/mortgages/recos/update-request`,
				{
					recommenderRequestId: id,
					newStatus: status,
					comments: values.comments,
					blockingReasons: values.checkData
				},
				{
					headers: { Authorization: `Bearer ${authClient.getToken()}` }
				}
			)
			.then((response) => {
				window.location.reload();
			});
	};

	const renderStepTitle = ({ step, title, iconButton, handleClick }: StepTitleProps) => {
		if ((step === 1 && !statusReco) || step === 5) {
			return (
				<>
					<span>{title}</span>
					{step === 1 && (
						<Radio.Group
							value={optionReco}
							onChange={handleChangeRadio}
							style={{ display: 'flex', flexDirection: 'column', gap: 4, marginTop: 8 }}
							options={[
								{
									value: 'recommender',
									label: (
										<Text style={{ fontSize: 12, color: '#748EA0', fontWeight: 400 }}>
											<strong>Pedir</strong> RECO F1
										</Text>
									)
								},
								{
									value: 'reactive',
									label: (
										<Text style={{ fontSize: 12, color: '#748EA0', fontWeight: 400 }}>
											Lo hago <strong>yo</strong>
										</Text>
									)
								}
							]}></Radio.Group>
					)}
					{step === 5 && (
						<div onClick={handleClick} className="button-reco" style={{ marginTop: 4 }}>
							<Text
								className={
									step === 5 && disabledButtonCall()
										? 'text-button-disabled-reco'
										: 'text-button-reco'
								}>
								{textButtonCall()}
							</Text>
							{!disabledButtonCall() ? <>{iconButton}</> : null}
						</div>
					)}
				</>
			);
		} else if (step === 1 && recosList.length > 0) {
			const currentState = requestStates[statusReco];
			const copyRecosList = [...recosList];
			return (
				<>
					<span>{title}</span>
					{recosList.length > 1 && (
						<Popover
							content={
								<ol className="list-reco">
									{copyRecosList
										.reverse()
										.slice(0, -1)
										.filter((item: any) => item.status === 'done' || item.status === 'canceled')
										.map((item: any, index: number) => {
											const statusDate = item.recosState.find(
												(e: any) => e.status === item.status
											).statusDate;
											return (
												<li key={item.id}>
													Solicitud: {dayjs(item.createdAt).format('DD-MM-YYYY')} | Respuesta:{' '}
													{dayjs(statusDate).format('DD-MM-YYYY')}
												</li>
											);
										})}
								</ol>
							}
							title={
								<Text style={{ fontSize: 12, fontWeight: 600, color: '#2F4858' }}>
									Historial de RECO's en Fase 1
								</Text>
							}
							trigger={'click'}>
							<InfoCircleOutlined style={{ fontSize: 16, color: '#2F4858', marginLeft: 4 }} />
						</Popover>
					)}
					{analist && statusReco !== 'done' && statusReco !== 'canceled' && (
						<div style={{ marginTop: 16 }}>
							<Select
								disabled={
									!(
										(analist.type === 'risk' &&
											(statusReco === 'pending' || statusReco === 'blocked')) ||
										(analist.type === 'prerisk' &&
											((analist.area === 'recommenders' && statusReco !== 'blocked') ||
												(analist.area === 'documents' && statusReco === 'blocked')))
									)
								}
								onChange={(value) => selectModal(value, recosList[0])}
								defaultValue={statusReco}
								className={`select-status select-status-${statusReco}`}>
								{renderOptions(statusReco)}
							</Select>
						</div>
					)}
					{statusReco === 'done' && (
						<div style={{ display: 'flex', marginTop: 16, justifyContent: 'center' }}>
							<CheckOutlined style={{ color: '#0088C6' }} />
							<Text style={{ color: '#0088C6', fontSize: 12, fontWeight: 500, marginLeft: 5 }}>
								Finalizado
							</Text>
						</div>
					)}
					{statusReco === 'canceled' && (
						<div style={{ display: 'flex', marginTop: 16 }}>
							<CloseCircleFilled style={{ color: '#2f4858' }} />
							<Text style={{ color: '#2f4858', fontSize: 12, fontWeight: 500, marginLeft: 5 }}>
								Cancelado
							</Text>
						</div>
					)}
				</>
			);
		} else {
			return (
				<>
					<span>{title}</span>
					<br />
					<Text style={{ fontSize: 12, fontWeight: 400, color: '#2F4858' }}>
						(Scoring reactivo)
					</Text>
				</>
			);
		}
	};

	const handleChangeRadio = (e: any) => {
		const selectedValue = e.target.value;
		setOptionReco(selectedValue);
		validateRequest(selectedValue);
	};

	const renderStepDescription = ({
		step,
		datepickerLabel,
		selectLabel,
		defaultValue1,
		defaultValue2,
		defaultValue3,
		onChange1,
		onChange2,
		onChange3,
		options
	}: StepDescriptionProps) => {
		return (
			<div className="form-reco" style={{ marginTop: 8 }}>
				{step !== 6 ? (
					<>
						<div style={{ textAlign: 'left' }}>
							<Text className="label-reco">
								{step === 3
									? 'Scoring elaborado'
									: step === 4
									? 'ER pte. aprobación'
									: 'Primera solicitud'}
							</Text>
							<DatePicker
								disabled={disabledDatepicker(step)}
								className="datepicker-reco"
								placeholder="00-00-0000"
								onChange={onChange1}
								format={'DD-MM-YYYY'}
								value={defaultValue1 ? dayjs(defaultValue1) : undefined}
							/>
						</div>
						{step === 5 && (
							<Checkbox
								disabled={statusJob === 'finish' ? true : false}
								onChange={(e) => saveCallMade(e.target.checked, dayjs().format('YYYY-MM-DD'))}
								className="checkbox-reco"
								checked={mortgageData?.insuranceCall ? true : false}>
								<span>Llamada hecha</span>
							</Checkbox>
						)}
						<div style={{ textAlign: 'left' }}>
							<Text className="label-reco">{datepickerLabel}</Text>
							<DatePicker
								disabled={step === 1 || step === 5 ? true : false}
								className="datepicker-reco"
								placeholder="00-00-0000"
								onChange={onChange2}
								format={'DD-MM-YYYY'}
								value={defaultValue2 ? dayjs(defaultValue2) : undefined}
							/>
						</div>
						{step === 1 && (statusReco === 'done' || statusReco === 'canceled') && (
							<Radio.Group
								onChange={handleChangeRadio}
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: 4,
									marginTop: 8
								}}
								options={[
									{
										value: 'recommender',
										label: (
											<Text
												style={{
													fontSize: 12,
													color: '#748EA0',
													fontWeight: 400,
													whiteSpace: 'nowrap'
												}}>
												<strong>Pedir</strong> nuevo RECO F1
											</Text>
										)
									},
									{
										value: 'reactive',
										label: (
											<Text
												style={{
													fontSize: 12,
													color: '#748EA0',
													fontWeight: 400,
													whiteSpace: 'nowrap'
												}}>
												Lo hago <strong>yo</strong> de nuevo
											</Text>
										)
									}
								]}></Radio.Group>
						)}
						{step !== 1 && step !== 5 && (
							<div style={{ textAlign: 'left' }}>
								<Text className="label-reco">{selectLabel}</Text>
								<Select
									style={{ width: 130 }}
									className="select-reco"
									placeholder="Seleccionar"
									onChange={onChange3}
									defaultValue={defaultValue3 ? defaultValue3 : undefined}
									options={options}
									showSearch={step === 4 ? true : false}
									filterOption={(input, option) =>
										option.label.toLowerCase().includes(input.toLowerCase())
									}
								/>
							</div>
						)}
					</>
				) : (
					<div className="form-reco">
						<div style={{ textAlign: 'left' }}>
							<Text className="label-reco">Nº de sinfonía</Text>
							<Input
								style={{ width: 130 }}
								className="input-reco"
								placeholder="S00000"
								value={symphonyNumber}
								onFocus={() => {
									if (!symphonyNumber) {
										setSymphonyNumber('S');
									}
								}}
								onChange={(e) => setSymphonyNumber(e.target.value)}
								onBlur={(e) => {
									if (e.target.value === 'S') {
										setSymphonyNumber(undefined);
									} else {
										saveData('mortgage', 'symphonyNumber', e.target.value);
									}
								}}
							/>
						</div>
						<div style={{ textAlign: 'left' }}>
							<Text className="label-reco">Fecha de traspaso</Text>
							<DatePicker
								format={'DD-MM-YYYY'}
								className="datepicker-reco"
								placeholder="00-00-0000"
								onChange={(date) =>
									saveData('mortgage', 'symphonyDate', dayjs(date).format('YYYY-MM-DD'))
								}
								defaultValue={defaultValue2 ? dayjs(defaultValue2) : undefined}
							/>
						</div>
						<div style={{ textAlign: 'left' }}>
							<Text className="label-reco">Nº de sinfonía 2</Text>
							<Input
								style={{ width: 130 }}
								className="input-reco"
								placeholder="2100000"
								value={symphonyNumber2}
								onFocus={() => {
									if (!symphonyNumber2) {
										setSymphonyNumber2('21');
									}
								}}
								onChange={(e) => setSymphonyNumber2(e.target.value)}
								onBlur={(e) => {
									if (e.target.value === '21') {
										setSymphonyNumber2(undefined);
									} else {
										saveData('mortgage', 'symphonyNumber2', e.target.value);
									}
								}}
							/>
						</div>
					</div>
				)}
			</div>
		);
	};

	return (
		<>
			{!_.isUndefined(recosList) && !_.isUndefined(statusReco) ? (
				<Steps className="reco-steps" labelPlacement="vertical">
					<Step
						title={renderStepTitle({
							step: 1,
							title: 'F1 Reco',
							iconButton: <LikeOutlined className="icon-button-reco" />
						})}
						description={renderStepDescription({
							step: 1,
							datepickerLabel: 'Finalizado',
							defaultValue1:
								recosList.length > 0 && dayjs(recosList[0].createdAt).format('YYYY-MM-DD'),
							defaultValue2:
								recosList[0]?.status === 'done' &&
								recosList[0].recosState.some((e: any) => e.status === 'done')
									? dayjs(
											recosList[0].recosState.find((e: any) => e.status === 'done').statusDate
									  ).format('YYYY-MM-DD')
									: undefined
						})}
					/>
					<Step
						title="Precio especial"
						description={renderStepDescription({
							step: 2,
							datepickerLabel: 'Respuesta',
							selectLabel: 'Resultado',
							defaultValue1: mortgageData?.specialPriceRequest,
							defaultValue2: mortgageData?.specialPriceResponse,
							defaultValue3: mortgageData?.specialPriceStatus,
							onChange1: (date) =>
								saveData('mortgage', 'specialPriceRequest', dayjs(date).format('YYYY-MM-DD')),
							onChange2: (date) =>
								saveData('mortgage', 'specialPriceResponse', dayjs(date).format('YYYY-MM-DD')),
							onChange3: (value) => saveData('mortgage', 'specialPriceStatus', value),
							options: [
								{ value: 'approved', label: 'Aprobada' },
								{ value: 'deny', label: 'Denegada' }
							]
						})}
					/>
					<Step
						title={renderStepTitle({ step: 3, title: 'Elab. Oferta' })}
						description={renderStepDescription({
							step: 3,
							datepickerLabel: 'ER elaborado',
							selectLabel: 'Resultado',
							defaultValue1: mortgageData?.elaboratedScoring,
							defaultValue2: mortgageData?.elaboratedER,
							defaultValue3: mortgageData?.resultManagement,
							onChange1: (date) =>
								saveData('mortgage', 'elaboratedScoring', dayjs(date).format('YYYY-MM-DD')),
							onChange2: (date) =>
								saveData('mortgage', 'elaboratedER', dayjs(date).format('YYYY-MM-DD')),
							onChange3: (value) => checkOfferType(value),
							options: [
								{ value: 'toGrant', label: 'A conceder' },
								{ value: 'toStudy', label: 'A estudiar' },
								{ value: 'toDeny', label: 'A denegar' }
							]
						})}
					/>
					<Step
						title="Expediente de riesgos"
						description={renderStepDescription({
							step: 4,
							datepickerLabel: 'ER aprobado',
							selectLabel: 'Aprobado por',
							defaultValue1: mortgageData?.elaboratedER,
							defaultValue2: mortgageData?.approvedER,
							defaultValue3: mortgageData?.approvedBy,
							onChange2: (date) =>
								saveData('mortgage', 'approvedER', dayjs(date).format('YYYY-MM-DD')),
							onChange3: (value) => saveData('mortgage', 'approvedBy', value),
							options: mortgageDirectors?.map((dir: any) => ({
								value: dir.name,
								label: dir.name
							}))
						})}
					/>
					<Step
						title={renderStepTitle({
							step: 5,
							title: 'Llamada seguro',
							iconButton: (
								<PhoneOutlined
									className="icon-button-reco"
									style={{ transform: 'rotate(90deg)' }}
								/>
							),
							handleClick: () => {
								favOffer?.bankRequest?.bankAccountData?.email === undefined
									? setVisibleConfirmComponent(true)
									: sendEmail(true);
							}
						})}
						description={renderStepDescription({
							step: 5,
							datepickerLabel: 'Realizada',
							defaultValue1: mortgageData?.sendEmailInsecuranceDate,
							defaultValue2: mortgageData.insuranceCall
								? mortgageData?.insuranceCallDate
								: undefined
						})}
					/>
					<Step
						title="Exp. Gestoría de Formalización"
						description={renderStepDescription({
							step: 6,
							defaultValue2: mortgageData?.symphonyDate
						})}
					/>
				</Steps>
			) : (
				<Loading />
			)}
			<RequestModal
				mortgageId={mortgageId}
				operationType={operationType}
				getRecoList={getRecoList}
				showModalRequest={visibleModalRequest}
				setShowModalRequest={setVisibleModalRequest}
				showModalErrors={visibleModalError}
				setShowModalErrors={setVisibleModalError}
				errorsList={errorsList}
				optionReco={optionReco}
				saveData={saveData}
			/>
			<RegisterAssigment
				visible={visibleConfirmComponent}
				onCreate={handleSubmitForm}
				onCancel={() => setVisibleConfirmComponent(!visibleConfirmComponent)}
				bankAccountData={''}
			/>

			{request && (
				<ModalStatus
					mortgageId={mortgageId}
					request={request}
					modalComments={modalComments}
					modalChecks={modalChecks}
					modalFinish={modalFinish}
					modalCancel={modalCancel}
					dataOperation={dataOperation}
					setModalComments={setModalComments}
					setModalChecks={setModalChecks}
					setModalFinish={setModalFinish}
					setModalCancel={setModalCancel}
					sendinComment={sendinComment}></ModalStatus>
			)}
		</>
	);
};

export default StepsRecommenders;
