import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Col, Form, Input, Layout, message, Row, Space, Select } from 'antd';
import { FacebookFilled, GoogleOutlined, LockTwoTone, MailTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { loginRedux, LoginReduxError, getProfile } from '../../store/auth/actions';
import auth from '../../Auth/Auth';
import queryString from 'query-string';
import env from '../../environment';

const { Content } = Layout;

function Login(props) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const query = queryString.parse(location.search);
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

	useEffect(() => {
		auth.getToken().length != 0 && navigate('/');

		if (!auth.isAuthenticated() && query.token) {
			console.log('LOGIN: tengo token', query.token);
			dispatch(
				getProfile({
					token: query.token
				})
			);
		}
	}, []);

	useEffect(() => {
		if (auth.isAuthenticated()) {
			navigate('/');
			window.location.reload();
		}
	}, [isAuthenticated]);

	const onFinish = async (values) => {
		const responseLogin = await dispatch(
			loginRedux(values.username, values.password, values.managementCode)
		);

		if (!responseLogin || !responseLogin.success) {
			message.error('Su usuario o contraseña no son correctos');
			if (responseLogin && responseLogin.message === 'Passwords not match') {
				dispatch(LoginReduxError());
			}
		}
	};

	const onFinishFailed = (errorInfo) => {
		message.error('Debe completar todos los datos');
	};

	return (
		<Content>
			<Row
				md={{ span: 12, offset: 6 }}
				justify="space-around"
				align="middle"
				style={{ height: '100%', paddingTop: '130px' }}>
				<Col lg={6} xs={24}>
					<Card style={{ backgroundColor: 'rgba(255, 255, 255, 0.75)' }}>
						<Form name="login" onFinish={onFinish} onFinishFailed={onFinishFailed}>
							<Form.Item style={{ textAlign: 'center' }}>
								<img
									src={'https://gibobs-prod-cdn.s3.eu-central-1.amazonaws.com/logos/logo-gmail.png'}
									style={{ height: '100px' }}
									alt="hadmin"></img>
							</Form.Item>
							<Form.Item
								name="username"
								rules={[{ required: true, message: 'Introduzca su email' }]}>
								<Input
									type="email"
									style={{
										border: '1px solid #C0DAEE',
										borderRadius: '4px'
									}}
									placeholder="Usuario"
								/>
							</Form.Item>
							<Form.Item
								name="password"
								rules={[
									{
										required: true,
										message: 'Por favor, introduzca su contraseña'
									}
								]}>
								<Input.Password
									style={{
										border: '1px solid #C0DAEE',
										borderRadius: '4px'
									}}
									placeholder="Contraseña"
								/>
							</Form.Item>
							<Form.Item name="managementCode">
								<Select placeholder="Selecciona la marca">
									<Option value="gibobs">Gibobs</Option>
									<Option value="sabadell-es">Sabadell</Option>
								</Select>
							</Form.Item>
							<Form.Item>
								<Button type="primary" htmlType="submit" block style={{ borderRadius: 0 }}>
									Acceder con gibobs
								</Button>
							</Form.Item>
							<Form.Item>
								<Button
									type="primary"
									htmlType="submit"
									block
									icon={<GoogleOutlined />}
									href={`${env.api.url}/auth/google?target=hadmin_login`}
									style={{ background: '#ee3314', border: 'none', borderRadius: 0 }}>
									Acceder con google
								</Button>
							</Form.Item>
						</Form>
					</Card>
				</Col>
			</Row>
		</Content>
	);
}

export default Login;
