import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Typography, Card, Button, notification } from 'antd';
import axios from 'axios';
import env from '../../../../../environment';
import authClient from '../../../../../Auth/Auth';
import numeral from 'numeral';
import { renderComponent } from './renderComponent';
import moment from 'moment';
import _ from 'lodash';

const { Text } = Typography;

const ProfileOfTheHolders = ({ owners, dataOwners, userMortageId, dataClient, nameBanks }) => {
	const [countries, setContries] = useState();
	const [linkBankFlipByOwner, setLinkBankFlipByOwner] = useState(undefined);

	useEffect(() => {
		axios.get(`${env.api.url}/v1/countries`).then((response) => {
			setContries(response.data.data);
		});
		getUrlBankflip();
	}, []);

	const nameOwners = {
		owner1: 'Titular 1',
		owner2: 'Titular 2',
		owner3: 'Titular 3',
		owner4: 'Titular 4',
		guarantor1: 'Avalista 1',
		guarantor2: 'Avalista 2',
		guarantor3: 'Avalista 3',
		guarantor4: 'Avalista 4',
		RENTAL: 'Alquiler',
		OWN_HOME: 'Casa propia',
		PARENTS_HOME: 'Vive con familiares'
	};

	const getUrlBankflip = (click) => {
		axios
			.get(`${env.api.url}/docs/bankflip/get-provider-reference/by-operation-id/${userMortageId}`, {
				headers: { Authorization: `Bearer ${authClient.getToken()}` }
			})
			.then((response) => {
				setLinkBankFlipByOwner(response.data.data);

				if (click) {
					window.open(
						'https://web.whatsapp.com/send?phone=' +
							renderPhone(owners, dataOwners) +
							'&text=' +
							'En este link ' +
							response.data.data?.[dataOwners]?.data?.widgetLink +
							' te puedes descargar los documentos de ' +
							renderName(owners, dataOwners),
						'_blank'
					);
				}
			});
	};

	const calculatedAge = (age) => {
		if (moment(age).format('DD-MM-YYYY') !== 'Fecha inválida') {
			return moment(moment().format('YYYY-MM-DD')).diff(
				moment(moment(age).format('YYYY-MM-DD')),
				'years'
			);
		}
		if (moment(age).format('DD-MM-YYYY') === 'Fecha inválida') {
			return moment(moment().format('YYYY-MM-DD')).diff(
				moment(moment(age.split('-').reverse().join('-')).format('YYYY-MM-DD')),
				'years'
			);
		}
	};

	const getFormatData = (title, data, type, numberOfPayments, withoutTitle) => (
		<div>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: '#2F4858',
					marginRight: '4px'
				}}>
				{!withoutTitle ? title + ':' : ''}
			</Text>
			<Text
				style={{
					fontSize: '12px',
					fontWeight: '500',
					color: type === 'INCOME' ? '#52C41A' : '#02C3CD'
				}}>
				{data}
			</Text>
			{numberOfPayments && (
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '400',
						color: '#748EA0'
					}}>
					{numberOfPayments}
				</Text>
			)}
		</div>
	);
	const createSessionBankFlip = (dataOwners, click, owners) => {
		if (owners[dataOwners]?.nif) {
			axios
				.post(
					`${env.api.url}/docs/bankflip/create-session`,
					{
						operationId: userMortageId,
						personKey: dataOwners,
						isHadmin: true,
						nif: owners[dataOwners]?.nif
					},
					{
						headers: { Authorization: `Bearer ${authClient.getToken()}` }
					}
				)
				.then((response) => {
					getUrlBankflip(click);
				});
		} else {
			notification.error({
				message: 'Error',
				description: 'Es necesario que pongas el DNI en los datos'
			});
		}
	};

	const renderPhone = (owners, dataOwners) => {
		if (_.isNil(owners[dataOwners]?.phone)) {
			let whatsappPhone = owners['owner1']?.phone;

			if (whatsappPhone.indexOf('+') === -1) {
				whatsappPhone = `+34${whatsappPhone}`;
			}
			if (whatsappPhone) {
				whatsappPhone = whatsappPhone.replace('+', '%2B');
			}

			return whatsappPhone;
		} else {
			let whatsappPhone = owners[dataOwners]?.phone;

			if (whatsappPhone.indexOf('+') === -1) {
				whatsappPhone = `+34${whatsappPhone}`;
			}
			if (whatsappPhone) {
				whatsappPhone = whatsappPhone.replace('+', '%2B');
			}

			return whatsappPhone;
		}
	};

	const getFormatDataMultiple = (title, data, type) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Text
					style={{
						fontSize: '12px',
						fontWeight: '500',
						color: '#2F4858',
						marginRight: '4px'
					}}>
					{title + ':'}
				</Text>
				<div>
					{data.length !== 0 ? (
						data.map((info) => {
							return (
								<div>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '500',
											color: type === 'INCOME' ? '#52C41A' : '#02C3CD'
										}}>
										{numeral(info.amount).format('0,0 $') + '/mes'}
									</Text>
									<Text
										style={{
											fontSize: '12px',
											fontWeight: '400',
											color: '#748EA0'
										}}>
										{' x ' + info.paymentsNumber} pagas
									</Text>
								</div>
							);
						})
					) : (
						<Text
							style={{
								fontSize: '12px',
								fontWeight: '500',
								color: type === 'INCOME' ? '#52C41A' : '#02C3CD'
							}}>
							{numeral(0).format('0,0 $') + '/mes'}
						</Text>
					)}
				</div>
			</div>
		);
	};

	const renderName = (owners, dataOwners) => {
		if (_.isNil(owners[dataOwners]?.name)) {
			return 'Titular 2';
		} else {
			return owners[dataOwners].name;
		}
	};
	const infoOwner = (name) => (
		<Text
			style={{
				fontSize: '12px',
				fontWeight: '500',
				color: '#2F4858',
				marginLeft: '8px'
			}}>
			{name}
		</Text>
	);

	const getDataRenderComponent = (type, index) => {
		if (dataClient) {
			return renderComponent(type).options.map((info) => {
				if (info.value === owners[dataOwners][type]) {
					return (
						<Text
							strong
							className="title-principal-data-new"
							style={{
								color: '#02C3CD',
								fontSize: type === 'maritalStatus' && 12
							}}
							key={info.label}>
							{info.label}
						</Text>
					);
				}
			});
		} else {
			if (type === 'activity') {
				const arrayTmp = [];
				renderComponent(type).options.map((info) => {
					if (
						owners[dataOwners]?.employment?.currentEmployments &&
						info.value === owners[dataOwners]?.employment?.currentEmployments[index][type]
					) {
						arrayTmp.push(
							<Text
								strong
								className="title-principal-data-new"
								style={{
									color: '#02C3CD',
									fontSize: type === 'maritalStatus' && 12
								}}
								key={info.label}>
								{info.label}
							</Text>
						);
					}
				});
				return arrayTmp;
			} else {
				return renderComponent(type).options.map((info) => {
					if (info.value === owners[dataOwners][type]) {
						return (
							<Text
								strong
								className="title-principal-data-new"
								style={{
									color: '#02C3CD',
									fontSize: type === 'maritalStatus' && 12
								}}
								key={info.label}>
								{info.label}
							</Text>
						);
					}
				});
			}
		}
	};

	const renderApe = (owner1) => {
		if (owner1?.firstSurname !== undefined && owner1?.lastSurname !== undefined) {
			return owner1?.firstSurname + ' ' + owner1?.lastSurname;
		} else if (owner1?.firstSurname !== undefined && owner1?.lastSurname === undefined) {
			return owner1?.firstSurname;
		} else if (owner1?.firstSurname !== undefined && owner1?.lastSurname !== undefined) {
			return owner1?.lastSurname;
		} else {
			return '';
		}
	};

	return (
		<>
			<Col lg={12} xs={24} style={{ paddingTop: '20px' }}>
				<Card
					style={{
						border: '1px solid #C0F0F3',
						borderRadius: '4px',
						backgroundColor: '#EFFCFC',
						marginTop: '10px',
						height: '100%'
					}}>
					<Row gutter={24}>
						<Col lg={21} style={{ display: 'flex' }}>
							<div style={{ marginBottom: '10px', lineHeight: '20px' }}>
								<Text className="title-principal-data-new">{nameOwners[dataOwners]}</Text>
							</div>
							{dataOwners &&
							dataClient === false &&
							linkBankFlipByOwner !== undefined &&
							linkBankFlipByOwner?.[dataOwners]?.data?.widgetLink !== undefined &&
							linkBankFlipByOwner?.[dataOwners]?.data?.flags?.isHadmin &&
							_.isNil(linkBankFlipByOwner?.[dataOwners]?.data?.flags?.sessionCompleted) ? (
								<a
									href={
										'https://web.whatsapp.com/send?phone=' +
										renderPhone(owners, dataOwners) +
										'&text=' +
										'En este link ' +
										linkBankFlipByOwner?.[dataOwners]?.data?.widgetLink +
										' te puedes descargar los documentos de ' +
										renderName(owners, dataOwners)
									}
									target="_blank"
									rel="noreferrer">
									<Button
										className="button-secundary-gibobs"
										onClick={() => createSessionBankFlip(dataOwners, true, owners)}
										style={{ fontSize: 12, height: 24, marginLeft: 12 }}>
										Compartir magicLink
									</Button>
								</a>
							) : (
								dataClient === false && (
									<Button
										className="button-secundary-gibobs"
										onClick={() => createSessionBankFlip(dataOwners, true, owners)}
										style={{ fontSize: 12, height: 24, marginLeft: 12 }}>
										Compartir magicLink
									</Button>
								)
							)}
						</Col>
					</Row>
					<div style={{ display: 'flex', columnGap: 8, marginBottom: 16 }}>
						<div style={{ lineHeight: '16px' }}>
							<Text
								style={{
									fontSize: '12px',
									fontWeight: '500',
									color: '#2F4858',
									marginRight: '4px'
								}}>
								{owners && owners[dataOwners] && owners[dataOwners].name
									? owners[dataOwners].name + ' ' + renderApe(owners[dataOwners])
									: `Nombre ${nameOwners[dataOwners]}`}
							</Text>
						</div>
						<div style={{ lineHeight: '16px' }}>
							<Text style={{ fontSize: '12px', fontWeight: '600', color: '#02C3CD' }}>
								{owners && owners[dataOwners] && owners[dataOwners].phone
									? owners[dataOwners].phone
									: '-'}
							</Text>
						</div>
						<div style={{ lineHeight: '16px' }}>
							<Text
								style={{
									fontSize: '12px',
									fontWeight: 'normal',
									color: '#748EA0',
									marginRight: '4px'
								}}>
								{owners && owners[dataOwners] && owners[dataOwners].email
									? owners[dataOwners].email
									: `Correo ${nameOwners[dataOwners]}`}
							</Text>
						</div>
					</div>

					<Row gutter={24} style={{ marginLeft: -12, marginRight: 0 }}>
						<Col lg={8} xs={24}>
							{getFormatData(
								'DNI',
								owners && owners[dataOwners] && owners[dataOwners].nif
									? owners[dataOwners].nif
									: '-'
							)}
						</Col>
						<Col lg={10} xs={24}>
							{getFormatData(
								'Fecha de nacimiento',
								owners && owners[dataOwners] && owners[dataOwners].birthdate
									? moment(owners[dataOwners].birthdate).format('DD-MM-YYYY')
									: owners[dataOwners]?.age !== undefined
									? moment().subtract(owners[dataOwners]?.age, 'years').format('DD-MM-YYYY')
									: '-'
							)}
						</Col>
						<Col lg={6} xs={24}>
							{getFormatData(
								'Edad',
								owners && owners[dataOwners] && owners[dataOwners].birthdate
									? calculatedAge(owners[dataOwners].birthdate)
									: owners[dataOwners]?.age ?? '-'
							)}
						</Col>
					</Row>
					<Row style={{ marginRight: -12 }}>
						<Col lg={8} xs={24}>
							{getFormatData(
								'Estado civil',
								owners && owners[dataOwners] && owners[dataOwners].maritalStatus
									? getDataRenderComponent('maritalStatus')
									: '-'
							)}
						</Col>
						<Col lg={10} xs={24}>
							{getFormatData(
								'Nacionalidad',
								countries && owners && owners[dataOwners] && owners[dataOwners].nationality
									? countries.countries.map((countries) => {
											if (countries.code === owners[dataOwners].nationality) {
												return (
													<Text
														strong
														style={{
															fontSize: '12px',
															color: '#02C3CD'
														}}
														key={countries.code}>
														{countries.name_es}
													</Text>
												);
											}
									  })
									: '-'
							)}
						</Col>
						<Col lg={6} xs={24}>
							{getFormatData(
								'Residencia',
								countries && owners && owners[dataOwners] && owners[dataOwners]?.cityzenship
									? countries.countries.map((countries) => {
											if (countries.code === owners[dataOwners]?.cityzenship) {
												return (
													<Text
														strong
														style={{
															fontSize: '12px',
															color: '#02C3CD'
														}}
														key={countries.code}>
														{countries.name_es}
													</Text>
												);
											}
									  })
									: '-'
							)}
						</Col>
					</Row>
					<Row>
						<Col lg={8} xs={24}>
							{nameOwners[owners[dataOwners]?.currentlyLiving] !== undefined
								? getFormatData(
										'Donde vive',
										nameOwners[owners[dataOwners]?.currentlyLiving] +
											' ' +
											numeral(owners[dataOwners]?.rentalAmount).format('0,0 $') || '-'
								  )
								: getFormatData('Donde vive', '-')}
						</Col>
					</Row>

					<Row gutter={24} style={{ marginTop: 16 }}>
						{owners[dataOwners]?.employment?.currentEmployments ? (
							owners[dataOwners]?.employment?.currentEmployments?.map((data, index) => {
								return (
									<Col lg={24} xs={24}>
										<div>
											{data.activity
												? getDataRenderComponent('activity', index)
												: 'Situación laboral'}

											{infoOwner(
												data.seniority
													? data.seniority == 1
														? `${data.seniority} año`
														: `${data.seniority} años`
													: '0 años'
											)}
											{infoOwner(data.companyName || '-')}
											{infoOwner(data.currentProfession || '-')}
										</div>
									</Col>
								);
							})
						) : (
							<Col lg={24} xs={24}>
								<div>
									<Text>
										{owners && owners?.[dataOwners] && owners[dataOwners].activity
											? getDataRenderComponent('activity')
											: 'Situación laboral'}
									</Text>
									{infoOwner(
										owners && owners[dataOwners] && owners[dataOwners].seniority
											? owners[dataOwners].seniority + ' años'
											: '-'
									)}
									{infoOwner(owners[dataOwners]?.currentProfession || 'Empresa')}
									{infoOwner(owners[dataOwners]?.companyName || 'Profesión')}
								</div>
							</Col>
						)}
					</Row>

					<Row gutter={24} style={{ marginTop: 16 }}>
						<Col lg={24} xs={24}>
							<div style={{ marginBottom: '8px', lineHeight: '20px' }}>
								<Text className="title-principal-data-new">Ingresos y patrimonio</Text>
							</div>
						</Col>
						<Col lg={12} xs={12} style={{ borderRight: '1px solid #2F4858' }}>
							<Row gutter={24}>
								<Col lg={24} xs={24}>
									{owners[dataOwners]?.incomesWealth?.incomes
										? getFormatDataMultiple(
												'Nóminas',
												owners[dataOwners]?.incomesWealth?.incomes.filter(
													(data) => data.incomeType === 'SALARY'
												),
												'INCOME'
										  )
										: getFormatData(
												'Nóminas',
												numeral(owners[dataOwners]?.netIncomes).format('0,0 $') + '/mes' ||
													numeral(0).format('0,0 $'),
												'INCOME',
												owners && owners[dataOwners] && owners[dataOwners]?.numberOfPayments
													? ` x ${owners[dataOwners]?.numberOfPayments} pagas`
													: null
										  )}
								</Col>

								<Col lg={24} xs={24} style={{ marginTop: 8 }}>
									{owners[dataOwners]?.incomesWealth?.incomes
										? getFormatDataMultiple(
												'Bonus',
												owners[dataOwners]?.incomesWealth?.incomes.filter(
													(data) => data.incomeType === 'BONUS'
												),
												'INCOME'
										  )
										: getFormatData(
												'Bonus',
												numeral(owners[dataOwners]?.bonus).format('0,0 $') + '/mes',
												'INCOME'
										  )}
								</Col>

								<Col lg={24} xs={24} style={{ marginTop: 8 }}>
									{owners[dataOwners]?.incomesWealth?.incomes
										? getFormatDataMultiple(
												'Alquiler',
												owners[dataOwners]?.incomesWealth?.incomes.filter(
													(data) => data.incomeType === 'RENT'
												),
												'INCOME'
										  )
										: getFormatData(
												'Alquiler',
												numeral(owners[dataOwners]?.rentIncomes).format('0,0 $') + '/mes',
												'INCOME'
										  )}
								</Col>
							</Row>
						</Col>

						<Col lg={12} xs={12} style={{ alignSelf: 'center', columnGap: 8 }}>
							<Row gutter={24}>
								<Col lg={24} xs={24}>
									{getFormatData(
										'Ingreso disponible anual (0596-0017)',
										owners[dataOwners]?.incomesWealth?.IRPF_0017_0596
											? numeral(owners[dataOwners]?.incomesWealth?.IRPF_0017_0596).format('0,0 $')
											: '-',
										'INCOME'
									)}
									{getFormatData(
										'Ingreso neto anual',
										owners[dataOwners]?.incomesWealth?.totalAnnualNetIncome
											? numeral(owners[dataOwners]?.incomesWealth?.totalAnnualNetIncome).format(
													'0,0 $'
											  )
											: '-',
										'INCOME'
									)}
								</Col>
								<Col lg={24} xs={24} style={{ marginTop: 8 }}>
									{getFormatData(
										'Ingresos netos mensules',
										owners[dataOwners]?.incomesWealth?.proRataMonthlyNetIncome
											? numeral(owners[dataOwners]?.incomesWealth?.proRataMonthlyNetIncome).format(
													'0,0 $'
											  )
											: '-',
										'INCOME'
									)}
								</Col>
								<Col lg={24} xs={24} style={{ marginTop: 8 }}>
									{getFormatData(
										'RN reducido de AE (0235)',
										owners[dataOwners]?.incomesWealth?.sumOfNetReturnReducedEconomyActivity0235
											? numeral(
													owners[dataOwners]?.incomesWealth
														?.sumOfNetReturnReducedEconomyActivity0235
											  ).format('0,0 $')
											: '-',
										'INCOME'
									)}
								</Col>
							</Row>
						</Col>

						<Col span={24} style={{ marginTop: 24 }}>
							<Text
								style={{
									fontSize: '13px',
									fontWeight: '500',
									color: '#2F4858'
								}}>
								Viviendas en propiedad libres de carga
							</Text>
						</Col>
						{owners[dataOwners]?.incomesWealth?.ownedHouses?.map((data) => {
							return (
								<Col span={24} style={{ display: 'flex', columnGap: 4 }}>
									<div>
										{getFormatData(
											'Valor vivienda (aprox)',
											numeral(data.estimatedValue).format('0,0 $')
										)}
									</div>
									-<div>{getFormatData('Provincia', data.province)}</div>-
									<div>{getFormatData('Otros datos', data.moreInfo)}</div>
								</Col>
							);
						}) || (
							<Col span={24} style={{ display: 'flex', columnGap: 4 }}>
								-
							</Col>
						)}

						<Col lg={24} xs={24} style={{ marginTop: 44 }}>
							<div style={{ marginBottom: '8px' }}>
								<Text className="title-principal-data-new">Obligaciones de pago</Text>
							</div>
							<div style={{ lineHeight: '16px', marginBottom: '3px' }}>
								<Text
									className="info-payments-expenses-detail"
									style={{
										marginRight: '4px',
										color: '#2F4858'
									}}>
									Hipotecas:
								</Text>
								{owners[dataOwners]?.paymentObligations?.mortgage?.map((info) => {
									return (
										<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
											<Text className="info-payments-detail">
												{nameBanks?.map((nameBank) => {
													if (info.entity === nameBank.id) {
														return nameBank.name;
													}
												})}
											</Text>
											<Text className="info-payments-expenses-detail">
												{numeral(info.monthlyQuota).format('0,0 $') + '/mes'}
											</Text>
											<Text className="info-payments-detail">
												Pte {numeral(info.pendingCapital).format('0,0 $')}
											</Text>
											<Text className="info-payments-detail">
												de {numeral(info.initialCapital).format('0,0 $')}
											</Text>
											<Text className="info-payments-detail">
												Compra {numeral(info.purchasePrice).format('0,0 $')}
											</Text>
										</div>
									);
								}) || (
									<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
										<Text className="info-payments-expenses-detail">
											{numeral(owners[dataOwners]?.mortgagePayment).format('0,0 $') + '/mes'}
										</Text>
									</div>
								)}
							</div>
							<div style={{ lineHeight: '16px', marginBottom: '3px', marginTop: 8 }}>
								<Text
									className="info-payments-expenses-detail"
									style={{
										marginRight: '4px',
										color: '#2F4858'
									}}>
									Préstamos:
								</Text>
								{owners[dataOwners]?.paymentObligations?.activeLoan?.map((info) => {
									return (
										<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
											<Text className="info-payments-detail">
												{nameBanks?.map((nameBank) => {
													if (info.entity === nameBank.id) {
														return nameBank.name;
													}
												})}
											</Text>
											<Text className="info-payments-expenses-detail">
												{owners && owners[dataOwners]
													? numeral(info.monthlyQuota).format('0,0 $') + '/mes'
													: numeral(0).format('0,0 $') + '/mes'}
											</Text>
											<Text className="info-payments-detail">
												Pte {numeral(info.pendingCapital).format('0,0 $') + '/mes'}
											</Text>
											<Text className="info-payments-detail">Otros datos {info.otherData}</Text>
											<Text className="info-payments-detail">Objeto {info.loanObject}</Text>
										</div>
									);
								}) || (
									<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
										<Text className="info-payments-expenses-detail">
											{numeral(owners[dataOwners]?.loanPayment).format('0,0 $') + '/mes'}
										</Text>
									</div>
								)}

								<div style={{ lineHeight: '16px', marginTop: 8 }}>
									<Text
										className="info-payments-expenses-detail"
										style={{
											color: '#2F4858',
											marginRight: '4px'
										}}>
										Pensiones:
									</Text>
								</div>
								{owners[dataOwners]?.paymentObligations?.compensatoryPension?.map((info) => {
									return (
										<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
											<Text className="info-payments-detail">{info.concept || '-'}</Text>
											<Text className="info-payments-expenses-detail">
												{numeral(info.monthlyQuota).format('0,0 $') + '/mes'}
											</Text>
										</div>
									);
								}) || (
									<div style={{ display: 'flex', columnGap: 4, marginBottom: 4 }}>
										<Text className="info-payments-expenses-detail">
											{numeral(owners[dataOwners]?.pensionPayment).format('0,0 $') + '/mes'}
										</Text>
									</div>
								)}
							</div>
						</Col>
					</Row>
				</Card>
			</Col>
		</>
	);
};

export default ProfileOfTheHolders;
